import React from "react";
import classNames from "./landingcontainer.module.scss";

//assets
import bondsIcon from "../../static/images/icons/bonds.svg";
import defiIcon from "../../static/images/icons/defi.svg";
import sharetokenIcon from "../../static/images/icons/sharetoken.svg";
import cIcon from "../../static/images/icons/c.svg";
import moneymarketIcon from "../../static/images/icons/moneymarket.svg";
import walletIcon from "../../static/images/icons/wallet.svg";
import pIcon from "../../static/images/icons/p.svg";
import defiboxIcon from "../../static/images/icons/defibox.svg";

const LandingContainer = () => {
  return (
    <div className={classNames.landingContainer}>
      <div>
        <img src={bondsIcon} alt="bondsIcon" />
        <div className={classNames.title}>Bonds</div>
      </div>
      <div onClick={() => window.open("https://defirouting.com/", "_blank")}>
        <img src={defiIcon} alt="defiIcon" />
        <div className={classNames.title}>Routing</div>
      </div>
      <div>
        <img src={sharetokenIcon} alt="sharetokenIcon" />
        <div className={classNames.title}>Staking</div>
      </div>
      <div onClick={() => window.open("https://definitywealth.com/", "_blank")}>
        <img src={cIcon} alt="cIcon" />
        <div className={classNames.title}>Wealth</div>
      </div>
      <div onClick={() => window.open("https://moneymarkets.io/", "_blank")}>
        <img src={moneymarketIcon} alt="moneymarketIcon" />
        <div className={classNames.title}>MoneyMarkets</div>
      </div>
      <div onClick={() => window.open("https://app.defi.markets/", "_blank")}>
        <img src={walletIcon} alt="walletIcon" />
        <div className={classNames.title}>P2P Lending</div>
      </div>
      <div onClick={() => window.open("https://trust.defi.markets/", "_blank")}>
        <img src={pIcon} alt="pIcon" />
        <div className={classNames.title}>Trusts</div>
      </div>
      <div onClick={() => window.open("https://retired.app/", "_blank")}>
        <img src={defiboxIcon} alt="defiboxIcon" />
        <div className={classNames.title}>Retirement</div>
      </div>
    </div>
  );
};

export default LandingContainer;
