import Axios from "axios";
import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import OtpInput from "react-otp-input";
import { APP_CODE } from "../../config/appConfig";
import { BankContext } from "../../context/Context";
import LoadingAnim from "../LoadingAnim/LoadingAnim";

export const otpRegex = new RegExp(/^\d*$/);
function EnterPinUnlock({ onSucces, onClose, local }) {
  const { email, token, tostShowOn } = useContext(BankContext);
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [appLogo, setAppLogo] = useState("");

  const pinValidator = (pinStr) => {
    if (otpRegex.test(pinStr)) setPin(pinStr);
  };
  useEffect(() => {
    if (pin.length === 4) {
      if (local) {
        if (pin === local) {
          try {
            onSucces();
          } catch (error) {}
        } else {
          tostShowOn("Incorrect Pin");
          setPin("");
        }
      } else {
        setLoading(true);
        Axios.post(
          "https://comms.globalxchange.io/gxb/apps/user/validate/group/pin",
          {
            email: email,
            token: token,
            pin: pin,
            group_id: "66me7fdkhxsbtur",
          }
        )
          .then(({ data }) => {
            if (data.status) {
              try {
                onSucces();
              } catch (error) {}
            } else {
              tostShowOn(data.message || "Incorrect Pin");
              setPin("");
            }
          })
          .catch((err) => {
            tostShowOn(err.message || "Something Went Wrong");
            setPin("");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [pin]);
  return (
    <div className="enterPinUnlock">
      <div
        className="overlayClose"
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <div className="pinCard">
        {loading && (
          <div className="loadingAnim">
            <LoadingAnim />
          </div>
        )}
        <img src={appLogo} alt="" className="logo" />
        <div className="title">Enter Admin Pin</div>
        <OtpInput
          containerStyle="otp-input-wrapper"
          value={pin}
          onChange={(otp) => pinValidator(otp)}
          numInputs={4}
          separator={<span> </span>}
          inputStyle="otp-input"
        />
        {/* <div
          className="btnCloseFooter"
          onClick={() => {
            // try {
            //   onSucces();
            // } catch (error) {}
          }}
        >
          Submit
        </div> */}
        .
      </div>
    </div>
  );
}

export default EnterPinUnlock;
