import fullLogo from "../static/images/logos/fullLogo.svg";
import fullLogoTwo from "../static/images/logos/fullLogoTwo.svg";
import logoIcon from "../static/images/logos/logoIcon.svg";

export const FULL_LOGO = fullLogo;
export const FULL_LOGO_TWO = fullLogoTwo;
export const LOGO_ICON = logoIcon;

export const GX_API_ENDPOINT = "https://comms.globalxchange.io";

export const COUNTRY = "India";

export const NEW_CHAT_API = "https://testchatsioapi.globalxchange.io";

export const NEW_CHAT_SOCKET = "https://testsockchatsio.globalxchange.io";

export const SUPPORT_CHAT_URL = "https://chatsapi.globalxchange.io/gxchat/";

export const HEADER_ICON_SIZE = 20;

export const APP_USER_TOKEN = "app_user_token";

export const MOBILE_SIZE = 800;
