import React from 'react';
import { useAppContextDetails } from '../../context/AppContext';

function ModalConfirm({ onClose, onConfirm, text, setOpenModal }) {
  const { appName, appLogo } = useAppContextDetails();
  return (
    <div className="modalConfirm">
      <div
        className="overlayClose"
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
          setOpenModal(false);
        }}
      />
      <div className="modalContent">
        <div className="head">
          <img src={appLogo} alt="" />
          <h5>{appName}</h5>
        </div>
        <div className="contents">
          <div className="text">{text}</div>
          <div className="buttons">
            <div
              className="btn-confirm"
              onClick={() => {
                try {
                  onConfirm();
                } catch (error) {}
                setOpenModal(false);
              }}
            >
              Confirm
            </div>
            <div
              className="btn-cancel"
              onClick={() => {
                try {
                  onClose();
                } catch (error) {}
                setOpenModal(false);
              }}
            >
              Never Mind
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalConfirm;
