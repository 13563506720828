import React, { useState, useEffect, Fragment, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BiRightArrowAlt } from "react-icons/bi";
import { GrDocumentDownload } from "react-icons/gr";
import "../../static/scss/bondoverviewnew.scss";

import copyIcon from "../../static/images/icons/copy.svg";

import {
  faCaretDown,
  faCaretUp,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { faCopy } from "@fortawesome/free-regular-svg-icons";

import {
  FormatCurrency,
  FormatNumber,
  YesterdayToday,
} from "../../utils/FunctionTools";

import bondsicon from "../../staticimp/images/typeIcons/bondsicon.svg";
import paste from "../../staticimp/images/clipIcons/paste.svg";
import search from "../../staticimp/images/clipIcons/search.svg";
import LionBond from "../../components/SVGComponents/LionBond";
import MainLayout from "../../Layout/MainLayout";
import useWindowDimensions from "../../utilsold/WindowSize";
import { MOBILE_SIZE } from "../../config";
import { bondsDocumentFetch } from "../../APIS/bondsApi";
import axios from "axios";
import { GlobalContext } from "../../context/GlobalContext";
import { BankContext } from "../../context/Context";

// const email = '';

function BondOverview() {
  const { bondhash } = useParams();
  const [contractId, setContractId] = useState(bondhash);
  const [coinListObject, setCoinListObject] = useState({});
  const { selectedBondsNav, setSelectedBondsNav, productDropdown } =
    useContext(GlobalContext);
  const { email } = useContext(BankContext);
  const [bondsDocumentsApiData, setBondsDocumentsApiData] = useState();
  useEffect(() => {
    console.log("component mounted 2");
    Axios.post("https://comms.globalxchange.io/coin/vault/service/coins/get", {
      app_code: "ice",
    }).then((res) => {
      const { data } = res;
      if (data.status) {
        const { coins_data } = data;
        let coinObj = {};
        coins_data.forEach((coin) => {
          coinObj[coin.coinSymbol] = coin;
        });
        console.log(coinObj, "coinObj");
        setCoinListObject(coinObj);
      }
    });
  }, []);

  const [searchStr, setSearch] = useState("");
  const [contract, setContract] = useState({});
  const [loading, setLoading] = useState(true);

  const [openedFileDocument, setopenedFileDocument] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    console.log(bondhash, "bondhash");
    Axios.get(
      `https://comms.globalxchange.io/coin/iced/contract/get?_id=${bondhash}`
    )
      .then((res) => {
        const { data } = res;
        if (
          data.status &&
          data.icedContracts &&
          data.icedContracts[0] &&
          data.icedContracts[0].contracts &&
          data.icedContracts[0].contracts[0]
        )
          setContract(data.icedContracts[0].contracts[0]);
        else {
          console.log(data.message || "Something Went Wrong");
          navigate("/");
        }
      })
      .finally(() => {
        setLoading(false);
      }); // eslint-disable-next-line
  }, [contractId, bondhash]);

  const [listDetail, setListDetail] = useState(null);
  const [feesDetail, setFeesDetail] = useState(null);

  const { width } = useWindowDimensions();

  const feesItem = () => {
    switch (feesDetail) {
      case 1:
        return (
          <div className="listDetail sub">
            <div className="subHead">Broker Fees</div>
            <p>
              Broker Fees Are Deducted From You Gross Daily Earnings At A Rate
              Of {FormatNumber(contract && contract.feeRate, 2)}%. Therefore If
              You Are Earning $10.00 USD Today Your Broker Fee Would Be $
              {FormatNumber(0.1 * (contract && contract.feeRate), 2)} USD
            </p>
            <p className="nb">All Values In This Bonds Is Post Broker Fees</p>
            <FontAwesomeIcon
              onClick={() => {
                setFeesDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );
      case 2:
        return (
          <div className="listDetail sub">
            <div className="subHead">Issuance Fee</div>
            <p>
              Issuance Fees Are Deducted From The Bonds Upon Redemption. Daily
              Earning Power And Term Earning Power Calculations Are Pre-Issuance
              Fee While Net ROI Calculations Are Post Issuance Fees
            </p>
            <div className="item">
              <div className="label">Issuance Fee</div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract && contract.redemptionFee,
                    contract && contract.asset
                  )}{" "}
                  {contract && contract.asset}
                </div>
                <div className="secondary">
                  $
                  {FormatCurrency(contract && contract.redemptionFeeUSD, "USD")}
                </div>
              </div>
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setFeesDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );

      default:
        return (
          <>
            <div className="listItem sub mt-2" onClick={() => setFeesDetail(1)}>
              Broker Fees
              <FontAwesomeIcon icon={faCaretDown} />
            </div>
            <div className="listItem sub" onClick={() => setFeesDetail(2)}>
              Issuance Fee
              <FontAwesomeIcon icon={faCaretDown} />
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setListDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </>
        );
    }
  };
  const [copied, setCopied] = useState(false);
  const [enterPin, setEnterPin] = useState(false);
  const [showCopyAPI, setShowCopyAPI] = useState(false);

  const [numPage, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPage);
    setPageNumber(1);
  }

  const detailList = () => {
    switch (listDetail) {
      case 1:
        return (
          <div className="listDetail">
            <div className="head">Issuance Details</div>
            <div className="date">
              {moment(contract?.timestamp).format(
                "[Date: ] MMMM Do YYYY [At] hh:mm A z"
              )}
            </div>
            <div className="item">
              <div className="label">Bonds Currency</div>
              <div className="value">
                <div className="primary">
                  {coinListObject &&
                    contract &&
                    coinListObject[contract?.asset].coinName}
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Paid For With</div>
              <div className="value">
                <div className="primary">
                  {coinListObject &&
                    contract &&
                    coinListObject[contract?.coin].coinName}
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Purchased Where</div>
              <div className="value">
                <div className="primary">{contract?.origin_app_code}</div>
              </div>
            </div>
            <div className="item">
              <div className="label">
                <div className="primary">Cost Per Bonds</div>
                <div className="secondary">
                  Today’s USD Value or{" "}
                  {FormatCurrency(
                    contract && contract.contract_amount,
                    contract && contract.asset
                  )}{" "}
                  {contract && contract.asset}
                </div>
                <div className="secondary">
                  Initial USD Value For{" "}
                  {FormatCurrency(
                    contract && contract.contract_amount,
                    contract && contract.asset
                  )}{" "}
                  {contract && contract.asset}
                </div>
              </div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract && contract.contract_amount,
                    contract && contract.asset
                  )}{" "}
                  {contract && contract.asset}
                </div>
                <div className="secondary">
                  $
                  {FormatCurrency(
                    contract.contract_amount *
                      ((coinListObject &&
                        coinListObject[contract.asset] &&
                        coinListObject[contract.asset].price.USD) ||
                        1),
                    "USD"
                  )}
                </div>
                <div className="secondary">
                  {/* $
                  {FormatCurrency(
                    contract.contract_amount *
                      ((coinListObject &&
                        coinListObject[contract.asset] &&
                        coinListObject[contract.asset].price.USD) ||
                        1),
                    'USD'
                  )} */}
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Quantity</div>
              <div className="value">
                <div className="primary">
                  {contract && contract.num_of_bonds} Bonds
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">
                <div className="primary">Total Cost</div>
                <div className="secondary">
                  Today’s USD Value For{" "}
                  {FormatCurrency(
                    contract && contract.investment,
                    contract && contract.asset
                  )}{" "}
                  {contract && contract.asset}
                </div>
                <div className="secondary">
                  Initial USD Value For{" "}
                  {FormatCurrency(
                    contract && contract.investment,
                    contract && contract.asset
                  )}{" "}
                  {contract && contract.asset}
                </div>
              </div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract && contract.investment,
                    contract && contract.asset
                  )}{" "}
                  {contract && contract.asset}
                </div>
                <div className="secondary">
                  $
                  {FormatCurrency(
                    contract.investment *
                      ((coinListObject &&
                        coinListObject[contract.asset] &&
                        coinListObject[contract.asset].price.USD) ||
                        1),
                    "USD"
                  )}
                </div>
                <div className="secondary">
                  ${FormatCurrency(contract && contract.investment_usd, "USD")}
                </div>
              </div>
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setListDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );
      case 2:
        return (
          <div className="listDetail">
            <div className="head">Redemption Details</div>
            <div className="date">
              {moment(contract && contract.redemption_timestamp).format(
                "[Date: ] MMMM Do YYYY [At] hh:mm A z"
              )}
            </div>
            <div className="item">
              <div className="label">Redemption Value</div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract &&
                      contract.redemptionFee + contract.redemptionAmount,
                    contract && contract.asset
                  )}{" "}
                  {contract && contract.asset}
                </div>
                {/* <div className="secondary">
                  $
                  {FormatCurrency(
                    contract.redemptionFeeUSD + contract.redemptionAmountUSD,
                    'USD'
                  )}
                </div> */}
              </div>
            </div>
            <div className="item">
              <div className="label">Redemption Fee</div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract && contract.redemptionFee,
                    contract && contract.asset
                  )}{" "}
                  {contract && contract.asset}
                </div>
                {/* <div className="secondary">
                  $
                  {FormatCurrency(contract && contract.redemptionFeeUSD, 'USD')}
                </div> */}
              </div>
            </div>
            <div className="item">
              <div className="label">Gross Redemption Value</div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract && contract.redemptionAmount,
                    contract && contract.asset
                  )}{" "}
                  {contract && contract.asset}
                </div>
                {/* <div className="secondary">
                  $
                  {FormatCurrency(
                    contract && contract.redemptionAmountUSD,
                    'USD'
                  )}
                </div> */}
              </div>
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setListDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );
      case 3:
        return (
          <div className="listDetail">
            <div className="head">Daily Earning Power</div>
            <div className="date">{`${contract.days} Payments`}</div>
            <div className="item">
              <div className="label">Daily Rewards</div>
              <div className="value">
                <div className="primary">
                  {FormatNumber(contract && contract.interest_rate, 3)}%
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Daily Interest</div>
              <div className="value">
                <div className="primary">
                  {FormatNumber(
                    contract &&
                      (contract.investment * contract.interest_rate) / 100,
                    5
                  )}{" "}
                  {contract && contract.asset}
                </div>
                <div className="secondary">
                  $
                  {FormatCurrency(
                    contract &&
                      (contract.investment_usd * contract.interest_rate) / 100,
                    "USD"
                  )}
                </div>
              </div>
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setListDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );
      case 4:
        return (
          <div className="listDetail">
            <div className="head">Term Earning Power</div>
            <div className="date">{`${contract.days} Payments`}</div>
            <div className="item">
              <div className="label">Term Rewards</div>
              <div className="value">
                <div className="primary">
                  {FormatNumber(
                    contract && contract.interest_rate * contract.days,
                    3
                  )}
                  %
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Term Interest</div>
              <div className="value">
                <div className="primary">
                  {FormatNumber(
                    contract &&
                      (contract.investment *
                        contract.interest_rate *
                        contract.days) /
                        100,
                    5
                  )}{" "}
                  {contract && contract.asset}
                </div>
                <div className="secondary">
                  $
                  {FormatCurrency(
                    contract &&
                      (contract.investment_usd *
                        contract.interest_rate *
                        contract.days) /
                        100,
                    "USD"
                  )}
                </div>
              </div>
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setListDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );
      case 5:
        return (
          <div className="listDetail">
            <div className="head">Rewards Mechanics</div>
            <div className="date">
              Daily Rate =&nbsp;
              {FormatNumber(contract && contract.interest_rate, 3)}%
            </div>
            <div className="item">
              <div className="label">Base Compression Rate</div>
              <div className="value">
                <div className="primary">
                  {FormatNumber(
                    contract && contract.interest_factors.base_rate_lower,
                    3
                  )}
                  %
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Rewards Velocity</div>
              <div className="value">
                <div className="primary up">
                  {FormatNumber(
                    contract && contract.interest_factors.base_velocity,
                    2
                  )}
                  %
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Velocity Acceleration Factor</div>
              <div className="value">
                <div className="primary down">
                  {FormatNumber(
                    contract && -contract.interest_factors.acceleration,
                    2
                  )}
                </div>
              </div>
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setListDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );
      case 6:
        return (
          <div className="listDetail">
            <div className="head">Fees</div>
            <div className="date">2 Fee Structures</div>
            {feesItem()}
            <FontAwesomeIcon
              onClick={() => {
                setListDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );
      case 7:
        return (
          <div className="listDetail">
            <div className="head">Net ROI</div>
            <div className="item">
              <div className="label">Total Investment</div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract && contract.investment,
                    contract && contract.asset
                  )}
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Term Earnings</div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract && contract.initial_earning_power,
                    contract && contract.asset
                  )}
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Issuance Fees</div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract && contract.redemptionFee,
                    contract && contract.asset
                  )}
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Net Term Earnings</div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract &&
                      contract.initial_earning_power - contract.redemptionFee,
                    contract && contract.asset
                  )}
                </div>
              </div>
            </div>
            <div className="item big">
              <div className="label">Net ROI</div>
              <div className="value">
                <div className="primary up">
                  {FormatNumber(
                    contract &&
                      (contract.initial_earning_power / contract.investment) *
                        100,
                    2
                  )}
                  %
                </div>
              </div>
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setListDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );
      case "share":
        return (
          <>
            <div className="actionTitle">
              How Do You Want To Share This Bonds Hash?
            </div>
            <div className="actionOption">
              {copied ? (
                <div className="copied">
                  The Link Has Been Copied To Your Clipboard
                </div>
              ) : (
                <>
                  <div className="mainTitle">Copy Link</div>
                  <div className="subDesc">Copy The Link To Your Clipboard</div>
                  <FontAwesomeIcon
                    icon={faCopy}
                    className="faCpy"
                    onClick={() => {
                      navigator.clipboard
                        .writeText(`http://assets.io/bonds/${bondhash}`)
                        .then(() => {
                          setCopied(true);
                          setTimeout(() => {
                            setCopied(false);
                          }, 2000);
                        });
                    }}
                  />
                </>
              )}
            </div>
            <div className="actionOption disable">
              <div className="mainTitle">Email</div>
              <div className="subDesc">
                Email The Link To Your Desired Recipient
              </div>
              <FontAwesomeIcon icon={faChevronRight} className="faCpy" />
            </div>
            <div className="actionOption disable">
              <div className="mainTitle">Social Media</div>
              <div className="subDesc">
                Post The Bonds Hash On Any Of The Following Platforms
              </div>
              <FontAwesomeIcon icon={faChevronRight} className="faCpy" />
            </div>
          </>
        );
      case "owner":
        return (
          <>
            <div className="actionTitle">
              Select One Of The Following Actions?
            </div>
            <div
              className="actionOption"
              onClick={() => setListDetail("redeem")}
            >
              <div className="mainTitle">Redeem Bonds</div>
              <div className="subDesc">
                Extract The Priciple Back To A Liquid Vault
              </div>
            </div>
            <div
              className="actionOption disable"
              onClick={() => setListDetail("destination")}
            >
              <div className="mainTitle">Adjust Payout Destination</div>
              <div className="subDesc">Assets.io Liquid Vault</div>
              <FontAwesomeIcon icon={faChevronRight} className="faCpy" />
            </div>
            <div
              className="actionOption disable"
              onClick={() => setListDetail("frequency")}
            >
              <div className="mainTitle">Adjust Payout Frequency</div>
              <div className="subDesc">Daily</div>
              <FontAwesomeIcon icon={faChevronRight} className="faCpy" />
            </div>
            <div
              className="actionOption disable"
              onClick={() => setListDetail("exchange")}
            >
              <div className="mainTitle">Exchange The Bonds</div>
              <div className="subDesc">Coming Soon</div>
              <FontAwesomeIcon icon={faChevronRight} className="faCpy" />
            </div>
            <div
              className="actionOption disable"
              onClick={() => setListDetail("mortage")}
            >
              <div className="mainTitle">Mortgage The Bonds</div>
              <div className="subDesc">Coming Soon</div>
              <FontAwesomeIcon icon={faChevronRight} className="faCpy" />
            </div>
            <div className="actionOption" onClick={() => setEnterPin(true)}>
              <div className="mainTitle">Protocol Interface</div>
              <div className="subDesc">Admin Access Key Needed</div>
              <FontAwesomeIcon icon={faChevronRight} className="faCpy" />
            </div>
          </>
        );
      case "redeem":
      case "destination":
      case "frequency":
      case "exchange":
      case "mortage":
        if (email !== contract.email) {
          return (
            <div className="loginValidateText">
              The Logged In User Is Not The Owner
            </div>
          );
        } else {
          return (
            <div className="loginValidateText">
              Please Login To Validate That You Are The Owner
            </div>
          );
        }

      default:
        return (
          <>
            <div
              className="listItem"
              style={{ padding: width < MOBILE_SIZE ? "10% 20px" : "" }}
              onClick={() => setListDetail(1)}
            >
              Issuance Details
              {/* <FontAwesomeIcon icon={faCaretDown} /> */}
              <BiRightArrowAlt
                style={{
                  background: "#f3f4f5",
                  padding: "5px",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div
              className="listItem"
              style={{ padding: width < MOBILE_SIZE ? "10% 20px" : "" }}
              onClick={() => setListDetail(2)}
            >
              Redemption Details
              {/* <FontAwesomeIcon icon={faCaretDown} /> */}
              <BiRightArrowAlt
                style={{
                  background: "#f3f4f5",
                  padding: "5px",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div
              className="listItem"
              style={{ padding: width < MOBILE_SIZE ? "10% 20px" : "" }}
              onClick={() => setListDetail(3)}
            >
              Daily Earning Power
              {/* <FontAwesomeIcon icon={faCaretDown} /> */}
              <BiRightArrowAlt
                style={{
                  background: "#f3f4f5",
                  padding: "5px",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div
              className="listItem"
              style={{ padding: width < MOBILE_SIZE ? "10% 20px" : "" }}
              onClick={() => setListDetail(4)}
            >
              Term Earning Power
              {/* <FontAwesomeIcon icon={faCaretDown} /> */}
              <BiRightArrowAlt
                style={{
                  background: "#f3f4f5",
                  padding: "5px",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div
              className="listItem"
              style={{ padding: width < MOBILE_SIZE ? "10% 20px" : "" }}
              onClick={() => setListDetail(5)}
            >
              Rewards Mechanics
              {/* <FontAwesomeIcon icon={faCaretDown} /> */}
              <BiRightArrowAlt
                style={{
                  background: "#f3f4f5",
                  padding: "5px",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div
              className="listItem"
              style={{ padding: width < MOBILE_SIZE ? "10% 20px" : "" }}
              onClick={() => setListDetail(6)}
            >
              Fees
              {/* <FontAwesomeIcon icon={faCaretDown} /> */}
              <BiRightArrowAlt
                style={{
                  background: "#f3f4f5",
                  padding: "5px",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div
              className="listItem"
              style={{ padding: width < MOBILE_SIZE ? "10% 20px" : "" }}
              onClick={() => setListDetail(7)}
            >
              Net ROI
              {/* <FontAwesomeIcon icon={faCaretDown} /> */}
              <BiRightArrowAlt
                style={{
                  background: "#f3f4f5",
                  padding: "5px",
                  borderRadius: "50%",
                }}
              />
            </div>
          </>
        );
    }
  };
  const [contractEarnings, setContractEarnings] = useState([]);
  const [contractEarningsOverView, setContractEarningsView] = useState({});
  const [txnLoading, setTxnLoading] = useState(true);

  useEffect(() => {
    setTxnLoading(true);
    bondhash &&
      Axios.get(
        `https://comms.globalxchange.io/coin/iced/interest/logs/get?email=${
          email ? email : ""
        }&contract_id=${bondhash}`
      )
        .then((res) => {
          const { data } = res;
          if (data.status) {
            setContractEarningsView({ ...data, interestLogs: undefined });
            // console.log(data?.interestLogs, "checkingggg");
            setContractEarnings(data.interestLogs);
          }
        })
        .finally(() => {
          setTxnLoading(false);
        });
  }, [contractId, bondhash]);

  const checkIsExpandValue = (num, coin) => {
    if (coin === "BTC" || coin === "ETH") {
      if (num && num < 0.0001) return true;
      else return false;
    } else {
      if (num && num < 0.01) return true;
      else return false;
    }
  };

  const [tiObject, setTiObject] = useState(false);
  let date = "";

  let coinSelected = coinListObject && coinListObject[contract.asset];

  const [bondsApiLoading, setBondsApiLoading] = useState(false);

  function openDocsFile() {
    setopenedFileDocument(!openedFileDocument);
  }

  useEffect(() => {
    if (selectedBondsNav == "Documents") {
      setBondsApiLoading(true);
      axios
        .get(
          `https://comms.globalxchange.io/gxb/apps/sharetoken/prospectus?bond_id=${bondhash}`
        )
        .then((res) => {
          console.log(res, "documents data");
          let apiresponse = res?.data?.data[0]?.other_data?.documents;
          let changetoarray = Object.values(apiresponse);
          // console.log("data documensytss", changetoarray);
          setBondsDocumentsApiData(changetoarray);
        })
        .catch((error) => {
          console.log(error, "documents error");
        });
      setBondsApiLoading(false);
    }
  }, [selectedBondsNav]);

  return (
    <MainLayout>
      <div
        className="bondsQueryNavbar"
        style={{ zIndex: productDropdown ? 3 : 2 }}
      >
        <ul>
          <li
            onClick={(event) => setSelectedBondsNav(event.target.textContent)}
            style={{
              fontWeight: selectedBondsNav === "Stats" ? 600 : "",
              borderBottom: selectedBondsNav === "Stats" ? "none" : "",
            }}
          >
            Stats
          </li>
          <li
            onClick={(event) => setSelectedBondsNav(event.target.textContent)}
            style={{
              fontWeight: selectedBondsNav === "Transactions" ? 600 : "",
              borderBottom: selectedBondsNav === "Transactions" ? "none" : "",
            }}
          >
            Transactions
          </li>
          <li
            onClick={(event) => {
              setSelectedBondsNav(event.target.textContent);
            }}
            // onClick={documentsFetchData}
            style={{
              fontWeight: selectedBondsNav === "Documents" ? 600 : "",
              borderBottom: selectedBondsNav === "Documents" ? "none" : "",
            }}
          >
            Documents
          </li>
          <li
            // onClick={(event) => setSelectedBondsNav(event.target.textContent)}
            style={{
              fontWeight: selectedBondsNav === "Ownership" ? 600 : "",
            }}
          >
            Ownership
          </li>
          <li
            // onClick={(event) => setSelectedBondsNav(event.target.textContent)}
            style={{
              fontWeight: selectedBondsNav === "Taxes" ? 600 : "",
              borderBottom: selectedBondsNav === "Taxes" ? "none" : "",
            }}
          >
            Taxes
          </li>
        </ul>
      </div>
      <div
        className="bondsQueryNavbarMobile"
        style={{ zIndex: productDropdown ? 3 : 2 }}
      >
        <ul>
          <li
            onClick={(event) => setSelectedBondsNav(event.target.textContent)}
            className={selectedBondsNav === "Stats" ? "selected" : ""}
          >
            Stats
          </li>
          <li
            onClick={(event) => setSelectedBondsNav(event.target.textContent)}
            className={selectedBondsNav === "Transactions" ? "selected" : ""}
          >
            Transactions
          </li>
          <li
            onClick={(event) => {
              setSelectedBondsNav(event.target.textContent);
            }}
            className={selectedBondsNav === "Documents" ? "selected" : ""}
            // onClick={documentsFetchData}
          >
            Documents
          </li>
          <li
            // onClick={(event) => setSelectedBondsNav(event.target.textContent)}
            className={selectedBondsNav === "Ownership" ? "selected" : ""}
          >
            Ownership
          </li>
          <li
            // onClick={(event) => setSelectedBondsNav(event.target.textContent)}
            className={selectedBondsNav === "Taxes" ? "selected" : ""}
          >
            Taxes
          </li>
        </ul>
      </div>
      <div className="bondOverview">
        {loading ? (
          <div className="loaderParent" style={{ height: "100%" }}>
            <div class="loader"></div>
          </div>
        ) : (
          <>
            <div
              className="overView"
              // style={{ display: width < MOBILE_SIZE ? "none" : "block" }}
            >
              <div className="searchWrapper" style={{ display: "none" }}>
                <img src={bondsicon} alt="" className="m-0" />
                <input
                  type="text"
                  value={searchStr}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search Any Asset Hash..."
                />
                <img
                  src={paste}
                  alt=""
                  onClick={() => {
                    navigator.clipboard
                      .readText()
                      .then((clipText) => setSearch(clipText));
                  }}
                />
                <img
                  src={search}
                  alt=""
                  onClick={() => {
                    setContractId(searchStr);
                  }}
                />
              </div>

              {selectedBondsNav == "Stats" ? (
                <div>
                  <div
                    className="bondTitle"
                    style={{
                      // padding: "55px 3rem",
                      borderBottom: "1px solid #e7e7e7",
                    }}
                  >
                    <div style={{ fontSize: "2.75rem" }}>
                      {" "}
                      {coinListObject &&
                        coinListObject[contract.asset] &&
                        coinListObject[contract.asset].coinName}{" "}
                      Defi Contract Hash
                    </div>
                    <div
                      style={{
                        marginTop: "0.75rem",
                        fontSize: "1.2rem",
                        fontWeight: "400",
                      }}
                    >
                      <span>{contractId}</span>
                      <span
                        onClick={() =>
                          navigator.clipboard.writeText(contractId)
                        }
                        style={{ cursor: "pointer", marginLeft: "0.75rem" }}
                      >
                        <img src={copyIcon} alt="copyIcon" />
                      </span>
                    </div>
                  </div>
                  <div className="contractValueMobile">
                    <div style={{ fontSize: "2.75rem" }}>
                      {" "}
                      $
                      {contract &&
                        FormatCurrency(
                          contract.current_voc *
                            ((coinListObject &&
                              coinListObject[contract.asset] &&
                              coinListObject[contract.asset].price.USD) ||
                              1),
                          "USD"
                        )}
                    </div>
                    <div>Value Of Contract</div>
                  </div>
                  <div className="bondValues">
                    <div className="item">
                      <div className="label">Payments Till Date</div>
                      <div className="value">
                        {contractEarningsOverView &&
                          contractEarningsOverView.interest_payments}
                      </div>
                    </div>
                    <div className="item">
                      <div className="label">Total Paid Interest</div>
                      <div className="value">
                        {FormatCurrency(
                          contractEarningsOverView &&
                            contractEarningsOverView.interest_paid,
                          contract && contract.asset
                        )}{" "}
                        {contract.asset} | $
                        {FormatCurrency(
                          contractEarningsOverView &&
                            contractEarningsOverView.interest_paid_usd,
                          "USD"
                        )}{" "}
                        USD
                      </div>
                    </div>
                    <div className="item">
                      <div className="label">Total Remaining Interest</div>
                      <div className="value">
                        {FormatCurrency(
                          contractEarningsOverView &&
                            contractEarningsOverView.interest_remaining,
                          contract && contract.asset
                        )}{" "}
                        {contract.asset} | $
                        {FormatCurrency(
                          contractEarningsOverView &&
                            contractEarningsOverView.interest_remaining_usd,
                          "USD"
                        )}{" "}
                        USD
                      </div>
                    </div>
                  </div>
                </div>
              ) : selectedBondsNav == "Transactions" ? (
                <Scrollbars
                  autoHide
                  className="earnings"
                  renderView={(props) => (
                    <div {...props} className="vaultsView" />
                  )}
                >
                  <div className="bondsTransactionNav">
                    <div style={{ fontSize: "2.75rem", fontWeight: "600" }}>
                      Transactions
                    </div>
                    <div
                      style={{
                        marginTop: "0.75rem",
                        fontSize: "1.2rem",
                        fontWeight: "400",
                      }}
                    >
                      <span>{contractId}</span>
                      <span
                        onClick={() =>
                          navigator.clipboard.writeText(contractId)
                        }
                        style={{ cursor: "pointer", marginLeft: "0.75rem" }}
                      >
                        <img src={copyIcon} alt="copyIcon" />
                      </span>
                    </div>
                  </div>
                  {txnLoading
                    ? Array(5)
                        .fill("")
                        .map((v, i) => (
                          <Fragment key={i}>
                            <Skeleton className="day" width={300} />
                            <div className="vaults-itm">
                              <Skeleton circle width={30} height={30} />
                              <div className="name-n-date mr-auto">
                                <Skeleton className="name" width={400} />
                                <Skeleton className="date" width={450} />
                              </div>
                              <div className={`credit`}>
                                <Skeleton className="value" width={450} />
                              </div>
                            </div>
                          </Fragment>
                        ))
                    : contractEarnings.map((txn) => {
                        function sameDay() {
                          if (
                            moment(txn.timestamp).format("MMDDYYYY") === date
                          ) {
                          } else {
                            date = moment(txn.timestamp).format("MMDDYYYY");
                            return (
                              <div className="day">
                                {YesterdayToday(txn.timestamp)}
                              </div>
                            );
                          }
                        }
                        return (
                          <Fragment key={txn._id}>
                            {sameDay()}
                            <div className="vaults-itm">
                              <img
                                src={coinSelected && coinSelected.coinImage}
                                alt=""
                              />
                              <div className="name-n-date mr-auto">
                                <div className="name">
                                  {txn.pid || txn.reason}
                                </div>
                                <div className="date">
                                  {moment(txn.timestamp).format(
                                    "MMMM Do YYYY [at] h:mm:ss A zz"
                                  )}
                                </div>
                              </div>
                              <div
                                className={`credit ${checkIsExpandValue(
                                  txn.earned_interest || 0,
                                  txn.coin
                                )}`}
                              >
                                <span
                                  className="expand"
                                  onClick={() => {
                                    setTiObject({
                                      timestamp: txn.timestamp,
                                      title: `${"Credit From Rewards Payment"}`,
                                      amount: txn.earned_interest,
                                      coin: txn.coin,
                                      current: txn.earned_usd_value,
                                      updated: txn.earned_usd_value,
                                    });
                                  }}
                                >
                                  Expand
                                </span>
                                <span className="value">
                                  {FormatCurrency(
                                    txn.earned_interest,
                                    txn.coin
                                  )}
                                </span>
                              </div>
                            </div>
                          </Fragment>
                        );
                      })}
                </Scrollbars>
              ) : selectedBondsNav == "Documents" ? (
                <div className="bondsQueryDocuments">
                  <div>
                    <div>Documents</div>
                    <div
                      style={{
                        marginTop: "0.75rem",
                        fontSize: "1.2rem",
                        fontWeight: "400",
                      }}
                    >
                      <span>{contractId}</span>
                      <span
                        onClick={() =>
                          navigator.clipboard.writeText(contractId)
                        }
                        style={{ cursor: "pointer", marginLeft: "0.75rem" }}
                      >
                        <img src={copyIcon} alt="copyIcon" />
                      </span>
                    </div>
                  </div>
                  <div className="documentsBondContent">
                    <div style={{ position: "absolute" }}></div>
                    {bondsApiLoading ? (
                      <div class="loader"></div>
                    ) : bondsDocumentsApiData &&
                      bondsDocumentsApiData.length > 0 ? (
                      bondsDocumentsApiData.map((data, index) => {
                        return (
                          <div key={data.name + index}>
                            <div>
                              <div
                                className={
                                  openedFileDocument
                                    ? "documentContainerImg documentOpened"
                                    : "documentContainerImg"
                                }
                              >
                                {data.valuetype == "image" ? (
                                  <img
                                    src={data.value}
                                    alt={data.name}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "contain",
                                    }}
                                  />
                                ) : data.valuetype == "file" ? (
                                  <div
                                    onClick={() => {
                                      console.log("working");
                                      window.open(data.value);
                                    }}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      color: "#464B4E",
                                      flexDirection: "column",
                                      gap: "5px",
                                    }}
                                  >
                                    <div>Preview</div>
                                    <div>Documents</div>
                                    <GrDocumentDownload />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      color: "#464B4E",
                                      flexDirection: "column",
                                      gap: "5px",
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                      border: "1px solid red",
                                    }}
                                  ></div>
                                )}
                              </div>
                            </div>
                            <div>{data.name}</div>
                          </div>
                        );
                      })
                    ) : (
                      // ) : !bondsApiLoading ? (
                      // <div class="loader"></div>
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className="bondCertificate"
              style={{
                // minWidth: width < MOBILE_SIZE ? "100%" : "",
                minHeight: width < MOBILE_SIZE ? "100%" : "",
                display:
                  width > MOBILE_SIZE || selectedBondsNav == "Stats"
                    ? ""
                    : "none",
              }}
            >
              {/* <LionBond
                text={`${
                  coinListObject &&
                  coinListObject[contract.asset] &&
                  coinListObject[contract.asset]?.coinName &&
                  coinListObject[contract.asset]?.coinName?.toUpperCase()
                } BOND`}
              /> */}
              <div className="contractValue">
                <div style={{ fontSize: "2.75rem" }}>
                  {" "}
                  $
                  {contract &&
                    FormatCurrency(
                      contract.current_voc *
                        ((coinListObject &&
                          coinListObject[contract.asset] &&
                          coinListObject[contract.asset].price.USD) ||
                          1),
                      "USD"
                    )}
                </div>
                <div>Value Of Contract</div>
              </div>
              {/* <div className="header">
                <div className="label">Value Of Bonds</div>
                <div className="value">
                  <span className="def">
                    {contract &&
                      FormatCurrency(contract.current_voc, contract.asset)}
                    {contract && contract.asset}
                  </span>
                  <span className="hov">
                    $
                    {contract &&
                      FormatCurrency(
                        contract.current_voc *
                          ((coinListObject &&
                            coinListObject[contract.asset] &&
                            coinListObject[contract.asset].price.USD) ||
                            1),
                        "USD"
                      )}
                  </span>
                </div>
              </div> */}
              <Scrollbars
                autoHide
                className="bondDetailScroll"
                renderView={(props) => <div {...props} className="view" />}
                renderThumbHorizontal={() => <div />}
                renderThumbVertical={() => <div />}
              >
                {detailList()}
              </Scrollbars>
              <div
                className="footerButtons"
                style={{ paddingBottom: width < MOBILE_SIZE ? "0" : "" }}
              >
                <div
                  className="btnShare"
                  onClick={() => {
                    if (listDetail === null) setListDetail("share");
                    else setListDetail(null);
                  }}
                  style={{
                    borderRight: "0.5px solid #E5E5E5",
                    justifyContent: "left",
                  }}
                >
                  {listDetail === null ? "Share" : "Back"}
                </div>

                <div
                  className="btnShare"
                  onClick={() => setListDetail("owner")}
                  style={{ borderRight: "0.5px solid #E5E5E5" }}
                >
                  Actions
                </div>

                {/* <div
                  className={`btnOwner ${listDetail === null}`}
                  onClick={() => setListDetail('owner')}
                >
                  Actions
                </div> */}
                <div className="btnShare" style={{ justifyContent: "right" }}>
                  Trade
                </div>
                {/* <div
                  className={`btnOwner ${listDetail === null}`}
                  onClick={() => setListDetail('owner')}
                >
                  Im The Owner
                </div> */}
              </div>
            </div>
          </>
        )}
        {/* {tiObject ? (
        <TransactionInspector setOpenModal={setTiObject} tiObject={tiObject} />
      ) : (
        ""
      )}
      {enterPin && (
        <EnterPinUnlock
          onSucces={() => {
            setEnterPin(false);
            setShowCopyAPI(true);
          }}
          onClose={() => setEnterPin(false)}
        />
      )}
      {showCopyAPI && (
        <CopyBondAPIModal
          onClose={() => {
            setShowCopyAPI(false);
          }}
          api={`https://comms.globalxchange.io/coin/iced/interest/logs/get?email=${email}&contract_id=${contractId}`}
        />
      )} */}
      </div>
    </MainLayout>
  );
}

export default BondOverview;
