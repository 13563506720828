import moment from 'moment';
import TimeAgo from 'javascript-time-ago';

export function FormatNumber(val, prec, def) {
  if (!isNaN(def)) {
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: def,
      minimumFractionDigits: def,
    }).format(isNaN(val) ? 0 : val);
  }
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: prec,
    minimumFractionDigits: prec,
  }).format(isNaN(val) || val);
}

export function FormatCurrency(value = 0, coin = 'USD', def) {
  if (def && !isNaN(def)) {
    return FormatNumber(value, def);
  }
  if (
    typeof coin === 'string' &&
    (coin?.toUpperCase() === 'BTC' || coin?.toUpperCase() === 'ETH')
  ) {
    if (value < 10) {
      return FormatNumber(value, 4);
    } else {
      return FormatNumber(value, 3);
    }
  }
  return FormatNumber(value, 2);
}

export function YesterdayToday(timestamp, format = 'MMMM Do YYYY') {
  if (moment(timestamp).format('MMDDYYYY') === moment().format('MMDDYYYY')) {
    return 'Today';
  } else if (
    moment(timestamp).format('MMDDYYYY') ===
    moment().add(-1, 'day').format('MMDDYYYY')
  ) {
    return 'Yesterday';
  } else {
    return moment(timestamp).format(format);
  }
}
export function IsValidURL(str) {
  const urlRegex = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return urlRegex.test(str) ? str : false;
}

export const timeAgoFormatter = (timestamp) => {
  let text = '';

  try {
    const timeAgo = new TimeAgo('en-US');
    text = timeAgo.format(timestamp);
  } catch (error) {}

  return text;
};

export function GetSortOrder(prop) {
  return function (a, b) {
    if (a[prop] < b[prop]) {
      return -1;
    } else if (a[prop] > b[prop]) {
      return 1;
    }
    return 0;
  };
}
