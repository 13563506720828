export function generateYearsBetween(startYear = 2000, endYear) {
  const endDate = endYear || new Date().getFullYear();
  let years = [];

  for (var i = startYear; i <= endDate; i++) {
    years.push(startYear);
    startYear++;
  }
  return years;
}

export function getAllDaysInMonth(year, month) {
  const date = new Date(year, month - 1, 1);

  const dates = [];

  while (date.getMonth() === month - 1) {
    dates.push(new Date(date).getDate());
    date.setDate(date.getDate() + 1);
  }

  return dates;
}
