import React, { useContext, useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import { useMutation } from 'react-query';
import classNames from './loginModal.module.scss';
import { FULL_LOGO } from '../../config';
import eyeIcon from '../../static/images/icons/eye.svg';
import eyeSlash from '../../static/images/icons/eyeSlash.svg';
import { BankContext } from '../../context/Context';
import LoadingAnim from '../LoadingAnim/LoadingAnim';
import { Link } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';

async function loginFunc(paramData) {
  const { data } = await axios.post(
    'https://gxauth.apimachine.com/gx/user/login',
    paramData
  );
  return data;
}

async function verifyEmail(paramData) {
  const { data } = await axios.post(
    'https://gxauth.apimachine.com/gx/user/confirm',
    paramData
  );
  return data;
}

async function resetPswdPin(paramData) {
  const { data } = await axios.post(
    'https://gxauth.apimachine.com/gx/user/password/forgot/confirm',
    paramData
  );
  return data;
}

async function forgotPswd(paramData) {
  const { data } = await axios.post(
    'https://gxauth.apimachine.com/gx/user/password/forgot/request',
    paramData
  );
  return data;
}

async function registerOnApp(email, app_code) {
  const { data } = await axios.post(
    'https://comms.globalxchange.io/gxb/apps/register/user',
    {
      email,
      app_code,
    }
  );
  return data;
}

const capRegex = new RegExp(/^.*[A-Z].*/);
const numRegex = new RegExp(/^.*[0-9].*/);
const speRegex = new RegExp(/^.*[!@#$%^&*()+=].*/);

function LoginModal({ onClose = () => {}, onSuccess = () => {}, logoParam }) {
  const [emailid, setEmailId] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [twoFaPin, setTwoFaPin] = useState();
  const [isReset, setIsReset] = useState(false);
  const { userLoginHandler, tostShowOn } = useContext(BankContext);
  const { appCode } = useAppContextDetails();

  const { mutate: registerApp } = useMutation(registerOnApp, {});
  const {
    data: loginData,
    isLoading,
    mutate,
  } = useMutation(loginFunc, {
    onSuccess: (data) => {
      if (data.status) {
        userLoginHandler(emailid, data.accessToken, data.idToken);
        registerApp(emailid, appCode);
        onSuccess(emailid);
      } else {
        tostShowOn(data.message || 'Some Thing Went Wrong!');
        setPassword('');
      }
    },
  });

  const loginvalidate = () => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailid)) {
      mutate({ email: emailid, password, totp_code: twoFaPin?.toString() });
    } else {
      tostShowOn('Enter Valid EmailId');
    }
  };

  function gerFromContent() {
    switch (true) {
      case isReset:
        return (
          <ResetForgotPassword
            emailid={emailid}
            loginvalidate={mutate}
            setEmailId={setEmailId}
          />
        );
      case loginData?.mfa:
        return <TwoFAForm twoFaPin={twoFaPin} setTwoFaPin={setTwoFaPin} />;
      case loginData?.resend_code:
        return <ResendCode emailid={emailid} loginvalidate={loginvalidate} />;
      case loginData?.resetPassword:
        return (
          <ForcedReset
            emailid={emailid}
            loginvalidate={mutate}
            oldPassword={password}
          />
        );
      case loginData?.passwordResetPassword:
        return <ForcedVerifyReset emailid={emailid} loginvalidate={mutate} />;
      default:
        return (
          <LoginForm
            showPassword={showPassword}
            setShowPassword={setShowPassword}
            emailid={emailid}
            setEmailId={setEmailId}
            password={password}
            setPassword={setPassword}
            setIsReset={setIsReset}
          />
        );
    }
  }

  return (
    <div className={classNames.loginModal}>
      <div
        className={classNames.overlayClose}
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <div className={classNames.loginCard}>
        <div className={classNames.inCard}>
          <img
            src={logoParam || FULL_LOGO}
            alt=""
            className={classNames.logo}
          />
          <div className={classNames.form}>{gerFromContent()}</div>
        </div>
        {isReset ||
        loginData?.resend_code ||
        loginData?.resetPassword ||
        loginData?.passwordResetPassword ? (
          ''
        ) : (
          <div className={classNames.footerBtns}>
            <Link to="/signup" className={classNames.btnReg}>
              <span>Get Started</span>
            </Link>
            <div
              className={classNames.btnLogin}
              onClick={() => {
                try {
                  loginvalidate();
                } catch (error) {}
              }}
            >
              <span>Login</span>
            </div>
          </div>
        )}
        {isLoading && (
          <div className={classNames.loadingAnim}>
            <LoadingAnim />
          </div>
        )}
      </div>
    </div>
  );
}

function LoginForm({
  showPassword,
  setShowPassword,
  emailid,
  setEmailId,
  password,
  setPassword,
  setIsReset,
}) {
  return (
    <>
      <div className={classNames.group}>
        <input
          type="text"
          name="email"
          value={emailid}
          onChange={(e) => setEmailId(e.target.value)}
          required="required"
        />
        <label>Email</label>
      </div>
      <div className={classNames.group}>
        <input
          type={showPassword ? 'text' : 'password'}
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required="required"
        />
        <img
          className={classNames.eye}
          onClick={() => {
            setShowPassword(!showPassword);
          }}
          src={showPassword ? eyeSlash : eyeIcon}
          alt=""
        />
        <label>Password</label>
      </div>
      <div className={classNames.forgot} onClick={() => setIsReset(true)}>
        Forgot Your Password?
      </div>
    </>
  );
}

function TwoFAForm({ twoFaPin, setTwoFaPin }) {
  return (
    <>
      <div className={classNames.twoFaLabel}>
        Enter The Code On Your Google Authenticator
      </div>
      <OtpInput
        containerStyle={classNames.otpInputWrapper}
        value={twoFaPin}
        onChange={(otp) => setTwoFaPin(otp)}
        numInputs={6}
        separator={<span> </span>}
        inputStyle={classNames.otpInput}
        shouldAutoFocus
      />
    </>
  );
}

function ResendCode({ emailid, loginvalidate }) {
  const { tostShowOn } = useContext(BankContext);
  //Form Values
  const [pin, setPin] = useState('');
  const [invalidPin, setInvalidPin] = useState(false);
  const { appName } = useAppContextDetails();

  const {
    data: loginData,
    isLoading: loading,
    mutate,
  } = useMutation(verifyEmail, {
    onSuccess: (data) => {
      if (data.status) {
      } else {
        setInvalidPin(true);
      }
    },
  });

  function getStep() {
    switch (true) {
      case loginData?.status:
        setTimeout(() => {
          loginvalidate();
        }, 2000);
        return (
          <div className={classNames.success}>
            <div className={classNames.succesTitle}>
              You Have Successfully Verified Your Email
            </div>
            <div className={classNames.succesBottom}>
              You Will Automatically Be Logged In
            </div>
          </div>
        );
      default:
        return (
          <>
            <h5>
              {invalidPin
                ? 'Incorrect Code. Please Enter It Again'
                : `We Need You To Verify Your Email. Please Enter The Code That Was Just Sent To Your ${appName} Email`}
            </h5>
            <OtpInput
              value={pin}
              onChange={setPin}
              numInputs={6}
              separator={<span> </span>}
              shouldAutoFocus
              containerStyle={classNames.otpInputWrapper}
              inputStyle={classNames.otpInput}
            />
            <div className={classNames.group}>
              <button
                type="submit"
                disabled={loading}
                className={classNames.btnMain}
                onClick={() => {
                  if (String(pin).length === 6) {
                    mutate({ email: emailid, code: pin });
                  }
                }}
              >
                {loading ? 'Loading...' : 'Enter'}
              </button>
            </div>
            {loading && (
              <div className={classNames.loadingAnim}>
                <LoadingAnim />
              </div>
            )}
          </>
        );
    }
  }
  return <>{getStep()}</>;
}

function ForcedReset({ emailid, loginvalidate, oldPassword }) {
  const { tostShowOn } = useContext(BankContext);
  const [step, setStep] = useState('start');
  //Form Values
  const [isValid, setIsValid] = useState({});
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { isLoading: loading, mutate } = useMutation(loginFunc, {
    onSuccess: (data) => {
      if (data.status) {
        setStep('succes');
      } else {
        tostShowOn(data.message);
      }
    },
  });

  useEffect(() => {
    setIsValid({
      password:
        capRegex.test(password) &&
        numRegex.test(password) &&
        speRegex.test(password) &&
        password.length >= 8,
      confirmPassword: confirmPassword === password,
    });
  }, [password, confirmPassword]);

  const [eye, setEye] = useState(false);

  function getStep() {
    switch (step) {
      case 'start':
        return (
          <div className={classNames.success}>
            <div className={classNames.succesTitle}>
              In Order To Login, We Need You To Need Update Your Password In
              Accordance With Our Security Standards.
            </div>
            <div className={classNames.group}>
              <button
                type="submit"
                className={classNames.btnMain}
                onClick={() => {
                  setStep('enterPassWord');
                }}
              >
                {loading ? 'Loading...' : 'Enter'}
              </button>
            </div>
          </div>
        );
      case 'enterPassWord':
        return (
          <>
            <h5>Enter The Verification Code</h5>
            <div
              className={`${classNames.group} ${classNames[isValid.password]}`}
            >
              <input
                type={eye ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && isValid.password) {
                    setStep('enterCPassWord');
                  }
                }}
              />
              <img
                className={classNames.eye}
                onClick={() => {
                  setEye(!eye);
                }}
                src={eye ? eyeSlash : eyeIcon}
                alt=""
              />
            </div>
            <div className={classNames.group}>
              <button
                type="submit"
                disabled={loading}
                className={classNames.btnMain}
                onClick={() => {
                  if (isValid.password) {
                    setStep('enterCPassWord');
                  }
                }}
              >
                {loading ? 'Loading...' : 'Enter'}
              </button>
            </div>
          </>
        );
      case 'enterCPassWord':
        return (
          <>
            <h5>Enter The Verification Code</h5>
            <div
              className={`${classNames.group} ${
                classNames[isValid.confirmPassword]
              }`}
            >
              <input
                type={eye ? 'text' : 'password'}
                placeholder="Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    mutate({
                      email: emailid,
                      password: oldPassword,
                      newPassword: password,
                    });
                  }
                }}
              />
              <img
                className={classNames.eye}
                onClick={() => {
                  setEye(!eye);
                }}
                src={eye ? eyeSlash : eyeIcon}
                alt=""
              />
            </div>
            <div className={classNames.group}>
              <button
                type="submit"
                disabled={loading}
                className={classNames.btnMain}
                onClick={() =>
                  mutate({
                    email: emailid,
                    password: oldPassword,
                    newPassword: password,
                  })
                }
              >
                {loading ? 'Loading...' : 'Enter'}
              </button>
            </div>
          </>
        );
      case 'succes':
        setTimeout(() => {
          loginvalidate({ password, email: emailid });
        }, 2000);
        return (
          <div className={classNames.success}>
            <div className={classNames.succesTitle}>
              You Have Successfully Reset Your Password
            </div>
            <div className={classNames.succesBottom}>
              You Will Automatically Be Logged In
            </div>
          </div>
        );
      default:
        return '';
    }
  }
  return <>{getStep()}</>;
}

function ForcedVerifyReset({ emailid, loginvalidate }) {
  const { tostShowOn } = useContext(BankContext);
  const [step, setStep] = useState('enterPin');
  const [invalidPin, setInvalidPin] = useState(false);

  //Form Values
  const [isValid, setIsValid] = useState({});
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [pin, setPin] = useState('');

  useEffect(() => {
    setIsValid({
      password:
        capRegex.test(password) &&
        numRegex.test(password) &&
        speRegex.test(password) &&
        password.length >= 8,
      confirmPassword: confirmPassword === password,
    });
  }, [password, confirmPassword]);

  const { isLoading: loading, mutate } = useMutation(resetPswdPin, {
    onSuccess: (data) => {
      if (data.status) {
        setStep('succes');
      } else {
        setInvalidPin(true);
        setStep('enterPin');
      }
    },
  });
  const [eye, setEye] = useState(false);
  const { appName } = useAppContextDetails();

  const resetPassword = () => {
    if (isValid.confirmPassword) {
      mutate({ email: emailid, code: pin, newPassword: password });
    } else {
      tostShowOn('Passwords Not Match');
    }
  };

  function getStep() {
    switch (step) {
      case 'enterPin':
        return (
          <>
            <h5>
              {invalidPin
                ? 'Incorrect Code. Please Enter It Again'
                : `We Need You To Verify Your Email. Please Enter The Code That Was Just Sent To Your ${appName} Email`}
            </h5>
            <OtpInput
              value={pin}
              onChange={setPin}
              numInputs={6}
              separator={<span> </span>}
              shouldAutoFocus
              containerStyle={classNames.otpInputWrapper}
              inputStyle={classNames.otpInput}
            />
            <div className={classNames.group}>
              <button
                type="submit"
                disabled={loading}
                className={classNames.btnMain}
                onClick={() => {
                  if (isValid.pin) {
                    setStep('enterPassWord');
                  }
                }}
              >
                {loading ? 'Loading...' : 'Enter'}
              </button>
            </div>
          </>
        );
      case 'enterPassWord':
        return (
          <>
            <h5>Enter The Verification Code</h5>
            <div
              className={`${classNames.group} ${classNames[isValid.password]}`}
            >
              <input
                type={eye ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && isValid.password) {
                    setStep('enterCPassWord');
                  }
                }}
              />
              <img
                className={classNames.eye}
                onClick={() => {
                  setEye(!eye);
                }}
                src={eye ? eyeSlash : eyeIcon}
                alt=""
              />
            </div>
            <div className={classNames.group}>
              <button
                type="submit"
                disabled={loading}
                className={classNames.btnMain}
                onClick={() => {
                  if (isValid.password) {
                    setStep('enterCPassWord');
                  }
                }}
              >
                {loading ? 'Loading...' : 'Enter'}
              </button>
            </div>
          </>
        );
      case 'enterCPassWord':
        return (
          <>
            <h5>Enter The Verification Code</h5>
            <div
              className={`${classNames.group} ${
                classNames[isValid.confirmPassword]
              }`}
            >
              <input
                type={eye ? 'text' : 'password'}
                placeholder="Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    resetPassword();
                  }
                }}
              />
              <img
                className={classNames.eye}
                onClick={() => {
                  setEye(!eye);
                }}
                src={eye ? eyeSlash : eyeIcon}
                alt=""
              />
            </div>
            <div className={classNames.group}>
              <button
                type="submit"
                disabled={loading}
                className={classNames.btnMain}
                onClick={() => resetPassword()}
              >
                {loading ? 'Loading...' : 'Enter'}
              </button>
            </div>
          </>
        );
      case 'succes':
        setTimeout(() => {
          loginvalidate({ password, email: emailid });
        }, 2000);
        return (
          <div className={classNames.success}>
            <div className={classNames.succesTitle}>
              You Have Successfully Reset Your Password
            </div>
            <div className={classNames.succesBottom}>
              You Will Automatically Be Logged In
            </div>
          </div>
        );
      default:
        return '';
    }
  }
  return <>{getStep()}</>;
}

function ResetForgotPassword({ emailid, setEmailId, loginvalidate }) {
  const { tostShowOn } = useContext(BankContext);
  const [inValidMail, setInValidMail] = useState(false);
  const [step, setStep] = useState('');

  //Form Values
  const [isValid, setIsValid] = useState({});
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [pin, setPin] = useState('');
  const { appCode } = useAppContextDetails();

  const { isLoading: loading, mutate } = useMutation(forgotPswd, {
    onSuccess: (data) => {
      if (data.status) {
        setStep('enterPin');
      } else {
        setInValidMail(true);
      }
    },
  });

  const { isLoading: loadingReset, mutate: mutateReset } = useMutation(
    resetPswdPin,
    {
      onSuccess: (data) => {
        if (data.status) {
          setStep('succes');
        } else {
          setInvalidPin(true);
          setStep('enterPin');
        }
      },
    }
  );

  useEffect(() => {
    setIsValid({
      password:
        capRegex.test(password) &&
        numRegex.test(password) &&
        speRegex.test(password) &&
        password.length >= 8,
      confirmPassword: confirmPassword === password,
      pin: String(pin).length === 6,
    });
  }, [password, confirmPassword, pin]);

  function sendPin() {
    mutate({
      email: emailid,
      app_code: appCode,
    });
  }
  const [invalidPin, setInvalidPin] = useState(false);
  function resetPassword() {
    mutateReset({
      email: emailid,
      code: pin,
      newPassword: password,
    });
  }

  const [eye, setEye] = useState(false);

  function getStep() {
    switch (step) {
      case 'enterPin':
        return (
          <>
            <h5>
              {invalidPin
                ? 'Incorrect Code. Please Enter It Again'
                : 'Enter The Verification Code'}
            </h5>
            <OtpInput
              value={pin}
              onChange={setPin}
              numInputs={6}
              separator={<span> </span>}
              shouldAutoFocus
              containerStyle={classNames.otpInputWrapper}
              inputStyle={classNames.otpInput}
            />
            <div className={classNames.group}>
              <button
                type="submit"
                disabled={loading || loadingReset}
                className={classNames.btnMain}
                onClick={() => {
                  if (isValid.pin) {
                    setStep('enterPassWord');
                  }
                }}
              >
                {loading || loadingReset ? 'Loading...' : 'Enter'}
              </button>
            </div>
          </>
        );
      case 'enterPassWord':
        return (
          <>
            <h5>Enter New Password</h5>
            <div
              className={`${classNames.group} ${classNames[isValid.password]}`}
            >
              <input
                type={eye ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && isValid.password) {
                    setStep('enterCPassWord');
                  }
                }}
              />
              <img
                className={classNames.eye}
                onClick={() => {
                  setEye(!eye);
                }}
                src={eye ? eyeSlash : eyeIcon}
                alt=""
              />
            </div>
            <div className={classNames.group}>
              <button
                type="submit"
                disabled={loading || loadingReset}
                className={classNames.btnMain}
                onClick={() => {
                  if (isValid.password) {
                    setStep('enterCPassWord');
                  }
                }}
              >
                {loading || loadingReset ? 'Loading...' : 'Enter'}
              </button>
            </div>
          </>
        );
      case 'enterCPassWord':
        return (
          <>
            <h5>Enter Confirm Password</h5>
            <div
              className={`${classNames.group} ${
                classNames[isValid.confirmPassword]
              }`}
            >
              <input
                type={eye ? 'text' : 'password'}
                placeholder="Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    resetPassword();
                  }
                }}
              />
              <img
                className={classNames.eye}
                onClick={() => {
                  setEye(!eye);
                }}
                src={eye ? eyeSlash : eyeIcon}
                alt=""
              />
            </div>
            <div className={classNames.group}>
              <button
                type="submit"
                disabled={loading || loadingReset}
                className={classNames.btnMain}
                onClick={() => resetPassword()}
              >
                {loading || loadingReset ? 'Loading...' : 'Enter'}
              </button>
            </div>
          </>
        );
      case 'succes':
        setTimeout(() => {
          loginvalidate({ password, email: emailid });
        }, 2000);
        return (
          <div className={classNames.success}>
            <div className={classNames.succesTitle}>
              You Have Successfully Reset Your Password
            </div>
            <div className={classNames.succesBottom}>
              You Will Automatically Be Logged In
            </div>
          </div>
        );
      default:
        return (
          <>
            <div className={classNames.group}>
              <input
                type="text"
                name="email"
                value={emailid}
                onChange={(e) => setEmailId(e.target.value)}
                required="required"
                onFocus={() => setInValidMail(false)}
                placeholder={inValidMail ? 'Invalid Mail' : 'Email'}
              />
            </div>
            <div className={classNames.group}>
              <button
                type="submit"
                disabled={loading || loadingReset}
                className={classNames.btnMain}
                onClick={() => sendPin()}
              >
                {loading || loadingReset ? 'Loading...' : 'Enter'}
              </button>
            </div>
          </>
        );
    }
  }
  return (
    <>
      {getStep()}
      {(loading || loadingReset) && (
        <div className={classNames.loadingAnim}>
          <LoadingAnim />
        </div>
      )}
    </>
  );
}

export default LoginModal;
