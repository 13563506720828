import React, { useRef, useState } from "react";
import "./bonds.css";
import searchMobileIcon from "../../static/images/icons/searchmobile.svg";
import bondsbtn1 from "../../assets/images/products/bondsbtn1.svg";
import bondsbtn2 from "../../assets/images/products/bondsbtn2.svg";
import elephantlogo from "../../assets/images/products/elephantlogo.jpg";
import { AiOutlineRight } from "react-icons/ai";
import { GiShare } from "react-icons/gi";
import { FiDownload } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useMarketContext } from "../../context/MarketContext";
import { GlobalContext } from "../../context/GlobalContext";
import { useContext } from "react";

import pasteIcon from "../../static/images/icons/paste.svg";
import { Link } from "react-router-dom";

const Bonds = () => {
  const navigate = useNavigate();
  const [sideMenu, setSideMenu] = useState(false);
  const [searchBondHash, setSearchBondHash] = useState();
  const { coinSelected, setCoinSelected } = useMarketContext();
  const { setSelectedBondsNav } = useContext(GlobalContext);

  const inputRef = useRef(null);

  const handlePaste = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");
    inputRef.current.value = pastedData;
  };

  return (
    <section className="productBonds">
      {/* <div className="bondsPageFirst">
        <div>
          <div>
            <div className="bondsHeading">Find Your Bonds Hash</div>
            <p className="bondsPara" style={{ fontSize: "calc(10px + 0.4vw)" }}>
              We are creating the most transparent staking market for crypto.
              Once you have purchased a Bonds, you can find all the contracts
              information in the Bonds Hash (SCH). Click here to see all your
              SCH’s.
            </p>
            <div className="bondsBtns">
              <div>
                <img src={bondsbtn1} alt="bondsbtn1" />
              </div>
              <div>
                <img src={bondsbtn2} alt="bondsbtn2" />
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div> */}
      <div className="bondsPageSecond">
        <div className="bondsContentContainer">
          <div className="bondsQues">Find Your Defi Hash</div>
          <div className="bondsSearch">
            <input
              type="text"
              placeholder="Enter Defi Hash"
              onChange={(event) => setSearchBondHash(event.target.value)}
              ref={inputRef}
              // onPaste={handlePaste}
            />
            {/* <div className="select">Instrument</div>
            <div className="select">Issuer</div> */}
            {/* <select name="certificate" id="bondscertificate">
              <option value="Instrument">Instrument</option>
            </select>
            <select name="certificate" id="bondscertificate">
              <option value="Issuer">Issuer</option>
            </select> */}
            {/* <div onClick={() => setSideMenu(!sideMenu)}>Search</div> ****old***  */}
            <img
              src={pasteIcon}
              alt="pasteIcon"
              onClick={() =>
                navigator.clipboard
                  .readText()
                  .then((text) => (inputRef.current.value = text))
              }
            />
            <div
              className="searchMobileIcon"
              onClick={() => {
                setCoinSelected("defihash");
                setSelectedBondsNav("Stats");
                navigate(`/hash/${searchBondHash}`);
              }}
            >
              <img src={searchMobileIcon} alt="searchMobileIcon" />
            </div>
            <Link
              onClick={() => {
                setSelectedBondsNav("Stats");
              }}
              to={`/hash/${searchBondHash}`}
            >
              Search
            </Link>
          </div>
        </div>
      </div>
      <div
        className="rightSideMenu"
        style={{
          transform: !sideMenu ? "translateX(150vw)" : "",
          display: "none",
        }}
      >
        <div>
          <div></div>
          <div></div>
          <div className="rightSideOverlapContainer">
            <div>
              <img src={elephantlogo} alt="elephantlogo" />
            </div>
            <div>
              <GiShare />
              <span>Share</span>
            </div>
            <div>
              <FiDownload />
              <span>Request Docs</span>
            </div>
          </div>
        </div>
        <div>
          <div className="rightSideMenuInput">
            <div>
              <div className="rsb-bold">IndianInvestor Bond</div>
              <div className="bondsPara">
                Issued In
                <span
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    paddingLeft: "6px",
                    cursor: "pointer",
                  }}
                >
                  India
                </span>
              </div>
            </div>
            <div>
              <div className="bondsPara">Payments Till Date</div>
              <div className="bondSideValues">22</div>
            </div>
            <div>
              <div className="bondsPara">Total Paid Interest</div>
              <div className="bondSideValues">0.0044 BTC | $1.25 USD</div>
            </div>
            <div>
              <div className="bondsPara">Total Remaining Interest</div>
              <div className="bondSideValues">0.0044 BTC | $1.25 USD</div>
            </div>
          </div>
          <div className="bondSideDetails">
            <div>
              <span>Issuance Details</span>
              <AiOutlineRight />
            </div>
            <div>
              <span>Issuance Details</span>
              <AiOutlineRight />
            </div>
            <div>
              <span>Issuance Details</span>
              <AiOutlineRight />
            </div>
          </div>
        </div>
      </div>
      <div
        className={!sideMenu ? "" : "overlayactive"}
        onClick={() => setSideMenu(!sideMenu)}
      ></div>
    </section>
  );
};

export default Bonds;
