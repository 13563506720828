import React, { useContext } from "react";
import classNames from "./mobilemenu.module.scss";
import { BankContext } from "../../context/Context";

const MobileMenu = () => {
  const { navOpen } = useContext(BankContext);

  return (
    <div
      className={classNames.mobileMenu}
      style={{ display: navOpen ? "" : "none" }}
    >
      <div className={classNames.menuOptions}>
        <div>Explorer</div>
        <div>Products</div>
        <div>Rates</div>
      </div>
      <div className={classNames.mainBtns}>
        <div onClick={() => window.open("https://marketsverse.com/", "_blank")}>
          Raise Capital
        </div>
        <div onClick={() => window.open("https://retired.app/", "_blank")}>
          Earn
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
