import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./navbar.module.scss";
import defiLogo from "../../static/images/logos/defiLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import { HiSearch, HiMenu } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { BankContext } from "../../context/Context";
import pasteIcon from "../../static/images/icons/paste.svg";

const Navbar = () => {
  const navigate = useNavigate();
  const [searchBondHash, setsearchBondHash] = useState("");
  const { navOpen, setNavOpen } = useContext(BankContext);

  useEffect(() => {
    let root = document.querySelector("body");
    if (navOpen) {
      root.style.overflow = "hidden";
    } else {
      root.style.overflow = "auto";
    }
  }, [navOpen]);

  const inputRef = useRef(null);

  const handlePaste = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");
    inputRef.current.value = pastedData;
  };

  return (
    <div className={classNames.navbar}>
      <div className={classNames.leftContainer}>
        <Link to="/">
          <img src={defiLogo} alt="defiLogo" />
        </Link>
        <div>
          <input
            type="text"
            placeholder="Enter Hash...."
            ref={inputRef}
            // onPaste={handlePaste}
            onChange={(event) => setsearchBondHash(event?.target?.value)}
          />
          <img
            src={pasteIcon}
            alt="pasteIcon"
            onClick={() =>
              navigator.clipboard
                .readText()
                .then((text) => (inputRef.current.value = text))
            }
          />
          <HiSearch
            onClick={() => {
              navigate(`/hash/${searchBondHash}`);
              // setsearchBondHash("");
            }}
          />
        </div>
      </div>
      <div className={classNames.rightContainer}>
        <div className={classNames.navigations}>
          <div>Explorer</div>
          <div>Products</div>
          <div>Rates</div>
        </div>
        <div className={classNames.btnsContainer}>
          <div
            onClick={() => window.open("https://marketsverse.com/", "_blank")}
          >
            Raise Capital
          </div>
          <div onClick={() => window.open("https://retired.app/", "_blank")}>
            Earn
          </div>
          {navOpen ? (
            <AiOutlineClose onClick={() => setNavOpen(!navOpen)} />
          ) : (
            <HiMenu onClick={() => setNavOpen(!navOpen)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
