import React from "react";
import Navbar from "../../components/Navbar";
import LandingContainer from "../../components/LandingContainer";
import Bonds from "../../components/Product/Bonds";

const LandingPage = () => {
  return (
    <>
      <Bonds />
      <LandingContainer />
    </>
  );
};

export default LandingPage;
