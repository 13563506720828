import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BankContext } from "../../../context/Context";
import { PortfolioContext } from "../../../context/PortfolioContext";
import iceLogo from "../../../static/images/logo.svg";

function IceMechineFooter() {
  const { email } = useContext(BankContext);
  const navigate = useNavigate();
  const { pathname: pageOnClose } = useLocation();
  const { setShowGrowAssets, setIceGrowTitle, setPageOnClose } =
    useContext(PortfolioContext);
  const [open, setOpen] = useState(true);
  return (
    <>
      {open ? (
        <div className={`growAssets${email ? "" : " d-none"}`}>
          <div className="head">
            <div
              className="textNBtns"
              onClick={() => {
                if (email) {
                  setShowGrowAssets(true);
                  setIceGrowTitle("Icing An Asset With The");
                  setPageOnClose(pageOnClose);
                  navigate("/iceAsset");
                }
              }}
            >
              <h6>
                <u>Click Here</u> To Use The
              </h6>
              <img src={iceLogo} alt="" />
              <h6>Machine</h6>
            </div>
          </div>
          <div className="closeDiv" onClick={() => setOpen(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default IceMechineFooter;
