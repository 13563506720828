import React from "react";
import Layout from "../../Layout/Layout";
import "./malls.scss";
import BondOverview from "../BondsOverview/BondOverview";

const BondHash = () => {
  return (
    <div className="transaction-layout">
      <BondOverview />
    </div>
  );
};

export default BondHash;
