import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import "./malls.scss";
import mall from "../../static/images/malls/mall-icon.svg";
import search from "../../static/images/malls/search.svg";
import img1 from "../../static/images/malls/ham.svg";
import notification from "../../static/images/malls/notification.svg";
import cart from "../../static/images/malls/cart.svg";
import ddown from "../../static/images/malls/ddown.svg";
import home from "../../static/images/malls/home-icon.svg";
import subscription from "../../static/images/malls/subscription.svg";
import offerTag from "../../static/images/malls/offerTag.svg";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppContextDetails } from "../../context/AppContext";
import { BankContext } from "../../context/Context";
import Skeleton from "react-loading-skeleton";
import Subscription from "./Subscription";
import Bonds from "../../components/Product/Bonds";

const BondsHome = () => {
  return (
    <div className="transaction-layout">
      <Bonds />
    </div>
  );
};

export default BondsHome;
