import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import { Routes } from "react-router-dom";
import GlobalContextProvider from "./context/GlobalContext";
import BondsHome from "./pages/BondsHome";
import BondHash from "./pages/BondHash";
import { MarketContextProvider } from "./context/MarketContext";
import AppContextProvider from "./context/AppContext";
import BankContextProvider from "./context/Context";
import PortfolioContextProvider from "./context/PortfolioContext";
import PlanBContextProvider from "./context/PlanBContext";
import Navbar from "./components/Navbar";
import MobileMenu from "./components/MobileMenu";

function MyRoutes() {
  return (
    <BankContextProvider>
      <AppContextProvider>
        <MarketContextProvider>
          <GlobalContextProvider>
            <PortfolioContextProvider>
              <PlanBContextProvider>
                <BrowserRouter>
                  <Navbar />
                  <MobileMenu />
                  <Routes>
                    <Route path="/" element={<LandingPage />} />
                    {/* <Route
                      exact
                      path="/products/bonds"
                      element={<BondsHome />}
                    /> */}
                    <Route
                      exact
                      path="/hash/:bondhash"
                      element={<BondHash />}
                    />
                  </Routes>
                </BrowserRouter>
              </PlanBContextProvider>
            </PortfolioContextProvider>
          </GlobalContextProvider>
        </MarketContextProvider>
      </AppContextProvider>
    </BankContextProvider>
  );
}

export default MyRoutes;
